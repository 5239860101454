import {substr} from "../../utils";

export default function CzptDetail({czpt}){

  return <div className="czpy-detail">
    <div className="czpt-detail-img-container">
      <img className="czpt-detail-img" alt={czpt.activityImages} src={czpt.activityImages}/>
    </div>
    <div className="czpt-detail-content">
      <h4 className="czpt-detail-title">{czpt.activityName}</h4>
      <p className="czpt-detail-description">
        {substr(czpt.activityDo, 50)}
      </p>
      <p className="czpt-detail-activity-price">
        活动价格：<span>￥{czpt.collaboratingPrice}</span>
      </p>
    </div>
  </div>
}
