import {useContext, useEffect, useState} from "react";
import TokenContext from "../../TokenContext";
import {getOtherActivity} from "../../api";
import cs from '../../../img/65586b70-9f11-4f63-ae80-645612988e6e.png'

export default function Qtcz() {
  const {token, updateToken} = useContext(TokenContext)
  const [qtczImg, setQtczImg] = useState("")

  const getQtcz = async () => {
    let resp = await getOtherActivity(token);
    console.log(resp)
    if (resp.code ===200){
      setQtczImg(resp.result.dictDataName)
    }
  }

  var toImage = function () {
    // var url = "/pages/index/webShowImage";
    // url += '?urlsStr=["'+qtczImg+'"]';
    // wx.miniProgram.navigateTo({ url: url });
    var url = "/pages/index/webShowImage";
    url += '?urlsStr=["https://trial.catdogtest.com/Images/Activity/二维码五个2.jpg"]';
    wx.miniProgram.navigateTo({ url: url });
  }


  useEffect(function () {
    getQtcz().then(r => {})
  },[token])

  const [pressing, setPressing] = useState(false);
  let timeoutId;

  const handleMouseDown = () => {
    timeoutId = setTimeout(() => {
      setPressing(true);
      toImage()
      // 在这里执行长按触发的操作
    }, 500); // 设置长按的时间阈值（以毫秒为单位）

    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutId);
    setPressing(false);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return <main
    style={{
      width: '100%',
      height: '100vh',
      background: '#fff',
    }}

    sx={{
      width: '100%',
      height: '100VH',
      background: '#fff',
    }}
  >
    <div style={{
      width: '100%',
      textAlign: 'center',
      paddingTop: '10px',
    }}>
      <img style={{
        width: '100%',
      }} src={qtczImg}
           onMouseDown={handleMouseDown}
           onMouseUp={handleMouseUp}

           alt={qtczImg}/>
    </div>
  </main>
}
