
import WdptItemCard from "../wdpt-item-card";
import {useContext, useEffect, useState} from "react";
import TokenContext from "../../TokenContext";
import {getOrderList} from "../../api";
export default function WdptItem({paymentStu, setCount}){
  const {token} = useContext(TokenContext)

  const [orderList, setOrderList] = useState([])
  const requestOrderListApi = async () => {
    let resp = await getOrderList(paymentStu, token);
    setOrderList(resp.result)
    let orders = resp.result;
    let filterOrder = orders.filter(function (item) {
     return item.state === 4
    })
    setCount(filterOrder.length)
  }

  useEffect(function () {
    requestOrderListApi()
    // setInterval(re
  },[paymentStu])


  return <div className={"wdpt-item-container"}>
    {
      orderList.map(function (order, index) {
        return <WdptItemCard key={index} load={requestOrderListApi} order={order}  />
      })
    }
  </div>
}
