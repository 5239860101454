import xmpt_img1 from '../img/xmpt-item1@2x.png'
import xmpt_img2 from '../img/xmpt-item2@2x.png'
let czpt_items = [
]


let xmpt_items = [
  {
    id:0,
    img: xmpt_img1,
    name: '肥厚型心肌病一型',
    originalPrice: 299,
    peopleNumber: 3,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'},
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img1,
    ]
  },
  {
    id:1,
    img: xmpt_img2,
    name: '孟加拉猫全套餐全套…',
    originalPrice: 299,
    peopleNumber: 5,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img2,
    ]
  },
  {
    id:2,
    img: xmpt_img1,
    name: '肥厚型心肌病一型',
    originalPrice: 299,
    peopleNumber: 3,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img1,
    ]
  },
  {
    id:3,
    img: xmpt_img2,
    name: '孟加拉猫全套餐全套…',
    originalPrice: 299,
    peopleNumber: 5,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img2,
    ]
  },
  {
    id:4,
    img: xmpt_img1,
    name: '肥厚型心肌病一型',
    originalPrice: 299,
    peopleNumber: 3,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img1,
    ]
  },
  {
    id:5,
    img: xmpt_img2,
    name: '孟加拉猫全套餐全套…',
    originalPrice: 299,
    peopleNumber: 5,
    discount_plans:[
      {name:"3折拼团", value: '3'},
      {name: "6折拼团", value: '6'}
    ],
    users: [
      xmpt_img2,
    ]
  }
]

export default {
  czpt_items,
  xmpt_items
}
