import { createRoot } from "react-dom/client";
import { App } from "./App";
import {TokenProvider} from "./TokenContext";

const container = document.getElementById("app");
const root = createRoot(container)
root.render(
  <TokenProvider>
    <App />
  </TokenProvider>
);
