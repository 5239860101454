import faqiren from '../../../img/faqiren.png'
export default function Avatar({src, title, bgColor}){
  if (!src){
    return <div className="avatar-container">
      <div className="avatar" >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg"
             viewBox="0 0 16 16">
          <path fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
        </svg>
      </div>
      {title? <img src={faqiren} className="avatar-badge"/>: <></>}
    </div>
  }
  return  <div className="avatar-container" >
    <img src={src} className="avatar" alt={src} style={bgColor? {backgroundColor: bgColor} : {}} />
    {title? <img src={faqiren} className="avatar-badge" /> : <></>}
  </div>
}
