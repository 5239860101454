import React, { useState } from 'react';
import './CustomAlert.css';

function CustomAlert({ message, onClose }) {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    if (onClose)onClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-container">
        <div className="custom-alert-header">
          {/*<h2>提示</h2>*/}
          <button className="custom-alert-close" onClick={handleClose}>
            <span>&times;</span>
          </button>
        </div>
        <div className="custom-alert-content">
          <p>{message}</p>
        </div>
        <div className="custom-alert-footer">
          <button className="custom-alert-button" onClick={handleClose}>
            确定
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomAlert;
