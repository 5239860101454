import {substr} from "../../utils";
import {Link} from "react-router-dom";

export default function XmptDetail({project}){

  return <div className="czpy-detail">
    <div className="czpt-detail-img-container">
      <Link to={`/gz?id=${project.projectId}&type=2`}>
        <img className="czpt-detail-img" alt={project.projectImages} src={project.projectImages}/>
      </Link>
    </div>
    <div className="czpt-detail-content">
      <h4 className="czpt-detail-title">{project.projectName}</h4>
      <p className="czpt-detail-description">
        {substr(project.projectDo, 50)}
      </p>
      <p className="czpt-detail-activity-price">
        活动价：<span>￥{project.collaboratingPrice}</span> <span id={"originalPrice"}>￥{project.originalPrice}</span>
      </p>
    </div>
  </div>
}
