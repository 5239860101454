

export const Get = async (url,token) => {

  const request = await (await fetch(
    url,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: token
      },
    },
  )).json();

  return request
}



export const Post = async (url, body, token) => {
  const request = await (await fetch(
    url,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(body)
    }
  )).json();

  if (!request.success) {
    const newError = {
      message: request.message,
    }
    throw newError;
  }

  return request;
}


export const Delete = async (url, body) => {
  const request = await (await fetch(
    url,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body
    }
  )).json();

  if (!request.success) {
    const newError = {
      message: request.message,
    }
    throw newError;
  }

  return request;
}


