import data from "../../data";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import TokenContext from "../../TokenContext";
import {getProjectList} from "../../api";
import {getItem, setItem, substr} from "../../utils";


export default function XmptItem({handleOpen}) {

  const {token} = useContext(TokenContext)
  const [items, setItems] = useState(getItem("xmpt_list"));

  const list = async () => {
    if(!token){
      return
    }
    let resp = await getProjectList(token);
    console.log(resp)
    if (resp.code ===200){
      setItems(resp.result)
      setItem("xmpt_list", resp.result)
    }
  }

  useEffect(function () {
    list().then(r => {})
  },[token])

  if (!items || items.length ===0){
    return <></>
  }

  return  <section id="xmpt">
    <div id="xmpt-header">
      <h4 style={{flexGrow: 1}}>
        项目拼团
      </h4>
      <Link to={"/czpt/gz?type=2"}>项目拼团规则</Link>
    </div>
    <div className="xmpt-body">
      {
        items.map(function (item) {
          if (item.collaboratingType === "1"){
            return <></>
          }
          return <div className="xmpt-items" key={item.projectId}>
            <div className="xmpt-img-container">
              <Link to={`/gz?id=${item.projectId}&type=2`}>
                <img src={item.projectImage} className="xmpt-item-img" alt="项目图片"/>
              </Link>
            </div>
            <p className="xmpt-item-title">
              {substr(item.projectName, 10)}
            </p>
            <p className="xmpt-price">
              项目原价：￥{item.projectOriginalPrice}
            </p>
            {/*item.buyNum*/}
            {
              item.projectDiscountPM?.map(function (discount) {
                return <button key={discount.discountId} onClick={()=>{
                  if (!discount.isCanBuy){
                    handleOpen(discount.buyNum)
                  }
                }} className={discount.isCanBuy? '' : 'disabled'}>
                  {discount.isCanBuy ?<Link to={`/xmpt20240628/${item.projectId}?discount=${discount.discountId}&isnosharebtn=true`}>
                      {discount.collaborating}折拼团
                    </Link>:
                  <Link>{discount.collaborating}折拼团</Link>}
                  </button>
              })
            }
          </div>
        })
      }
    </div>
  </section>
}
