import {useContext, useEffect, useState} from "react";
import data from "../../data";
import {Link} from "react-router-dom";
import item_img from '../../../img/qtcz.png'
import {getActivityList, getOtherActivity} from "../../api";
import TokenContext from "../../TokenContext";
import {getItem, setItem, substr} from "../../utils";

export default function CzptItem({handleOpen}){

  const {token, updateToken} = useContext(TokenContext)
  const [items, setItems] = useState(getItem("czpt_list"));

  const [qtczImg, setQtczImg] = useState("")

  const getQtcz = async () => {
    let resp = await getOtherActivity(token);
    console.log(resp)
    if (resp.code ===200){
      setQtczImg(resp.result.dictDataName)
    }
  }

  var toImage = function () {
    var url = "/pages/index/webShowImage";
    var png = JSON.stringify([qtczImg]);
    url += '?urlsStr='+png+'';
    wx.miniProgram.navigateTo({ url: url });
    // var url = "/pages/index/webShowImage";
    // url += '?urlsStr=["https://2trial.catdogtest.com/Images/Activity/二维码五个2.jpg"]';
    // wx.miniProgram.navigateTo({ url: url });
  }


  const list = async () => {
    if(!token){
      return
    }
    let resp = await getActivityList(token);
    console.log(resp)
    if (resp.code ===200){
      setItems(resp.result)
      setItem("czpt_list",resp.result)
    }
  }

  useEffect(function () {
    getQtcz().then(r => {})
    list().then(r => {})
  },[token])

  if (!items || items.length ===0){
    return <></>
  }
  return <section id="czpt">
    <div id="czpt-header">
      <h4 style={{flexGrow: 1}}>
        充值拼团
      </h4>
      <Link to="/czpt/gz?type=1">充值拼团规则</Link>
    </div>
    <div className="czpt-body">
      {
        items.map(function (item) {
          return <div className="czpt-items" key={item.activityId}>
            <div className="czpt-img-container">
                <img src={item.activityImages} className="czpt-item-img" alt="项目图片"/>
            </div>
            <p className="czpt-item-title">
              {item.activityName}
            </p>
            {/*<button className={item.isCanBuy? '' : 'disabled'}>*/}
            {/*  {*/}
            {/*    item.isCanBuy?*/}
            {/*      <Link to={`/xmpt20240628/${item.activityId}`}>参加拼团</Link>*/}
            {/*      : <Link to={`/czpt/gz?type=1`}>参加拼团</Link>*/}
            {/*  }*/}
            {/*</button>*/}
            {
              item.isCanBuy?
                <Link className={"btn"} to={`/czpt20240628/${item.activityId}?isnosharebtn=true`} >参加拼团</Link>
                : <button className={"disabled"} onClick={()=>handleOpen(item.buyNum)}>参加拼团</button>


            }
            {/*to={`/czpt/gz?type=1`}*/}
          </div>
        })
      }
      {
        qtczImg?<div className="czpt-items">
          <div className="czpt-img-container">
            <img src={item_img} className="czpt-item-img" alt="项目图片"/>
          </div>
          <p className="czpt-item-title">
            其他充值金额
          </p>
          <button className={'btn'} onClick={toImage}>
            充值
          </button>
        </div>:<></>
      }
    </div>
  </section>
}
